<template>
    <div>
        <div class="container-fluid">
            <div class="alert alert-success alert-dismissible fade show" v-if="$page.props.flash.success" role="alert">
                {{ $page.props.flash.success }}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="alert alert-info alert-dismissible fade show" v-if="$page.props.flash.errors" role="alert">
                {{ $page.props.flash.errors }}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <slot></slot>
        </div>
    </div>
</template>
<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
export default {
    data() {
        return {}
    },
}

</script>