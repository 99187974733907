<template>
  <div>
    <div :class="'card card-' + color">
      <div class="card-header">
        <h3 class="card-title">{{ titleCard }}</h3>
      </div>
      <form :action="actionRoute" :method="methodForm" enctype="multipart/form-data">
        <input type="hidden" name="_token" v-bind:value="csrfToken">
        <div class="card-body">
          <div class="form-row">
            <!-- CAMPO NOME -->
            <div class="form-group col-md-7">
              <label for="nome">Nome</label>
              <input v-if="$page.props.errors.nome" type="text" class="form-control is-invalid" name="nome" size="74"
                maxlength="70" autofocus placeholder="Nome Completo" style="text-transform: uppercase">
              <input v-else type="text" class="form-control" name="nome" size="74" maxlength="70" autofocus
                placeholder="Nome Completo" style="text-transform: uppercase">
              <div v-if="$page.props.errors.nome" class="invalid-feedback">{{ $page.props.errors.nome }}</div>
            </div>
            <!-- CAMPO SEXO -->
            <div class="form-group col-md-1">
              <label for="sexo">Sexo</label>
              <select class="form-select" name="sexo" required>
                <option value="M">M</option>
                <option value="F">F</option>
              </select>
            </div>
            <!-- CAMPO DATA -->
            <div class="form-group col-md-2">
              <label for="nacimento">Data</label>
              <input v-if="$page.props.errors.nacimento" type="text" class="form-control is-invalid" name="nacimento"
                id="data" size="7" maxlength="10" placeholder="00/00/000" style="text-transform: uppercase">
              <input v-else type="text" class="form-control" name="nacimento" id="data" size="7" maxlength="10"
                placeholder="00/00/000" style="text-transform: uppercase">
              <div v-if="$page.props.errors.nacimento" class="invalid-feedback">{{ $page.props.errors.nacimento }}</div>
            </div>
            <!-- CAMPO CPF -->
            <div class="form-group col-md-2">
              <label for="cpf">CPF</label>
              <input v-if="$page.props.errors.cpf" type="text" class="form-control is-invalid" name="cpf" id="cpf"
                placeholder="000.000.000-00" style="text-transform: uppercase" maxlength="14">
              <input v-else type="text" class="form-control" name="cpf" id="cpf" placeholder="000.000.000-00"
                style="text-transform: uppercase" maxlength="14">
              <div v-if="$page.props.errors.cpf" class="invalid-feedback">{{ $page.props.errors.cpf }}</div>
            </div>
            <!-- CAMPO CEP -->
            <div class="form-group col-md-2">
              <label for="cep">CEP</label>
              <input v-if="$page.props.errors.cep" type="text" class="form-control cep is-invalid" name="cep" id="cep"
                placeholder="00000-000" style="text-transform: uppercase" @blur="pesquisacep($event);" maxlength="9">
              <input v-else type="text" class="form-control cep" name="cep" id="cep" placeholder="00000-000"
                style="text-transform: uppercase" @blur="pesquisacep($event);" maxlength="9">
              <div v-if="$page.props.errors.cep" class="invalid-feedback">{{ $page.props.errors.cep }}</div>
            </div>
            <!-- CAMPO LOGRADOURO -->
            <div class="form-group col-md-5">
              <label for="rua">Logradouro</label>
              <input type="text" class="form-control" name="rua" id="rua" placeholder="Endereço"
                style="text-transform: uppercase">
            </div>
            <!-- CAMPO N° -->
            <div class="form-group col-md-1">
              <label for="numero">N°</label>
              <input v-if="$page.props.errors.numero" type="text" class="form-control is-invalid" name="numero"
                id="numero" placeholder="N°" maxlength="10" style="text-transform: uppercase">
              <input v-else type="text" class="form-control" name="numero" id="numero" placeholder="N°" maxlength="10"
                style="text-transform: uppercase">
              <div v-if="$page.props.errors.numero" class="invalid-feedback">{{ $page.props.errors.numero }}</div>
            </div>
            <!-- CAMPO BAIRRO -->
            <div class="form-group col-md-4">
              <label for="bairro">Bairro</label>
              <input type="text" class="form-control" name="bairro" id="bairro" placeholder="Bairro"
                style="text-transform: uppercase">
            </div>
            <!-- CAMPO CIDADE -->
            <div class="form-group col-md-5">
              <label for="cidade">Cidade</label>
              <input type="text" class="form-control" name="cidade" id="cidade" placeholder="Cidade"
                style="text-transform: uppercase">
            </div>
            <!-- CAMPO ESTADO -->
            <div class="form-group col-md-1">
              <label for="uf">Estado</label>
              <input type="text" class="form-control" value="RN" name="uf" id="uf" size="2" maxlength="2" placeholder="UF"
                style="text-transform: uppercase">
            </div>
            <!-- CAMPO IBGE -->
            <div v-show="title !== 'Pne'" class="form-group col-md-2" style="display: none;">
              <label for="ibge">IBGE</label>
              <input type="text" class="form-control" name="ibge" id="ibge" size="15" maxlength="15" placeholder="IBGE"
                style="text-transform: uppercase">
            </div>
            <!-- CAMPO ESCOLHER DATA -->
            <div v-show="title === 'Pne'" class="form-group col-md-2">
              <label for="validade">Validade</label>
              <input type="date" class="form-control" :min="minDate" :max="maxDate" name="validade" id="validade"
                style="text-transform: uppercase">
            </div>
            <!-- CAMPO TELEFONE -->
            <div class="form-group col-md-4">
              <label for="tel">Telefone</label>
              <input v-if="$page.props.errors.tel" type="text" class="form-control is-invalid" name="tel" id="tel"
                size="100" maxlength="100" placeholder="(00) 0000-0000" style="text-transform: uppercase">
              <input v-else type="text" class="form-control" name="tel" id="tel" size="100" maxlength="100"
                placeholder="(00) 0000-0000" style="text-transform: uppercase">
              <div v-if="$page.props.errors.tel" class="invalid-feedback">{{ $page.props.errors.tel }}</div>
            </div>
            <!-- CAMPO DATA NASCIMENTO -->
            <div class="form-group col-md-2">
              <label class="lab" for="saber">Anos</label>
              <input type="text" class="form-control" name="saber" id="age" style="text-transform: uppercase"
                placeholder="Data Nasc." maxlength="50" readonly="readonly">
            </div>
            <!-- CAMPO IDS ANTIGO -->
            <div class="form-group col-md-2">
              <label for="numero">IDS Antigo</label>
              <input type="text" class="form-control" name="ids_ant" id="ids_ant" style="text-transform: uppercase"
                placeholder="IDS Antigo" maxlength="50">
            </div>
            <!-- CAMPO OBSERVAÇÕES -->
            <div class="form-group col-md-8">
              <label for="obs">Observações</label>
              <textarea id="obs" onFocus="aoClicarOb()" onBlur="aoSairOb()" class="form-control" name="obs" rows="1"
                data-length="120" placeholder="Observações" style="text-transform: uppercase"></textarea>
            </div>
          </div>
          <div class="file-loading mt-4">
            <input id="file-es2" name="image[]" type="file" accept="image/*,application/pdf" multiple />
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" id="desabilita" :class="'btn btn-' + color">Enviar</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { format, addYears } from 'date-fns';

export default {
  props: [
    "titleCard",
    "actionRoute",
    "methodForm",
    "csrfToken",
    "color",
    "title",
  ],
  data() {
    return {
      escolherData: false,
      minDate: '',
      maxDate: ''
    };
  },
  methods: {
    async pesquisacep(event) {
      //Nova variável "cep" somente com dígitos.
      var cep = event.target.value.replace(/\D/g, "");
      //Verifica se campo cep possui valor informado.
      if (cep != "") {
        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(cep)) {
          //Preenche os campos com "..." enquanto consulta webservice.
          document.getElementById("rua").value = "...";
          document.getElementById("bairro").value = "...";
          document.getElementById("cidade").value = "...";
          document.getElementById("uf").value = "...";
          document.getElementById("ibge").value = "...";
        }
        ///get cep via axios
        const response = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );
        if (response.data.erro) {
          alert("CEP não encontrado.");
          document.getElementById("rua").value = "";
          document.getElementById("bairro").value = "";
          document.getElementById("cidade").value = "";
          document.getElementById("uf").value = "";
          document.getElementById("ibge").value = "";
        } else {
          //Atualiza os campos com os valores da consulta.
          document.getElementById("rua").value = response.data.logradouro;
          document.getElementById("bairro").value = response.data.bairro;
          document.getElementById("cidade").value = response.data.localidade;
          document.getElementById("uf").value = response.data.uf;
          document.getElementById("ibge").value = response.data.ibge;
        }
      }
    },
    getElemento() {
      if (this.title == "Pne") {
        document.getElementById("data").setAttribute("id", "data4");
        // this.escolherData = true;
      }
    },
    handleDateChange(date) {
      if (!date) {
        const currentDate = new Date();
        const futureDate = addYears(currentDate, 5);
        this.$refs.dateInput.value = format(futureDate, 'yyyy-MM-dd');
      }
    }
  },
  mounted() {
    console.log("Componente montado formCard.");
    this.getElemento();
    const currentDate = new Date();
    this.minDate = format(currentDate, 'yyyy-MM-dd');

    const futureDate = addYears(currentDate, 5);
    this.maxDate = format(futureDate, 'yyyy-MM-dd');
  },
  beforeDestroy() {
    console.log("Componente destruído formCard.");
  },
};
</script>
