<template>
  <AppLayout>

    <Head title="Criar Usuários"></Head>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Cadastrar usuários do sistema</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item active">Cadastrar usuários</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <div class="container-fluid">
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">Cadastro</h3>
        </div>
        <form action="/cadastrar" method="POST">
          <input type="hidden" name="_token" v-bind:value="csrfToken" />
          <div class="card-body">
            <div class="form-group">
              <label for="nome">Nome</label>
              <input type="text" class="form-control" id="nome" name="name" placeholder="Seu nome" />
            </div>
            <div class="form-group">
              <label for="email">E-mail</label>
              <input type="email" class="form-control" name="email" id="email" placeholder="E-mail" />
            </div>
            <div class="form-group">
              <label for="cpf">CPF</label>
              <input type="text" class="form-control" id="cpf" name="cpf" placeholder="000.000.000-00" />
            </div>
            <div class="form-group">
              <label>Tipo</label>
              <select class="form-control" name="tipo">
                <option>Escolha uma opção</option>
                <option value="admin" v-if="tipo == 'admin'">
                  Administrador
                </option>
                <option value="chefe">Chefe</option>
                <option value="cadastrador">Cadastrador</option>
                <option value="atendente">Atendente</option>
                <option value="user">Usuário</option>
              </select>
            </div>
            <div class="form-group">
              <label for="password">Senha</label>
              <input type="password" name="password" class="form-control" id="password" placeholder="Password" />
            </div>
          </div>
          <div class="card-footer">
            <butaoVue title="Cadastrar" type="submit" btnColor="btn-primary" btnSize="btn-lg" />
          </div>
        </form>
      </div>
    </div>
    <dataTableUser v-bind:tipo="tipo" v-bind:csrfToken="csrfToken" />
  </AppLayout>
</template>

<script>
import { Head } from "@inertiajs/inertia-vue";
import AppLayout from './Auth/Dashboard.vue';
import dataTableUser from "../components/DataTableUser.vue";
import ButaoVue from "../components/Butao.vue";
import axios from 'axios';
import config from "../config";
axios.create({
  baseURL: config.baseUrl,
});
export default {
  props: ["csrfToken", "tipo"],
  data() {
    return {
      allUsers: [],
    };
  },
  components: {
    dataTableUser,
    ButaoVue,
    Head,
    AppLayout,
  },
};
</script>
