<template>
<AppLayout>
  <Head title="Editar"></Head>
  <div class="row mb-2">
    <div class="col-sm-6">
      <h1 class="m-0">Editar Idoso</h1>
    </div>
    <div class="col-sm-6">
      <ol class="breadcrumb float-sm-right">
        <li class="breadcrumb-item"><a href="/home">Home</a></li>
        <li class="breadcrumb-item active">Idoso</li>
      </ol>
    </div>
  </div>
    <div class="card card-info" id="move">
      <div class="card-header">
        <h3 class="card-title">Cartão Idoso</h3>
      </div>
      <form action="/cadIdoso" method="POST">
      <input type="hidden" name="_token" v-bind:value="csrfToken">
        <div class="card-body">
          <div class="form-row">
            <!-- CAMPO NOME -->
            <div class="form-group col-md-7">
                <label for="nome">Nome</label>
                <input type="text" :value="idoso.nome" class="form-control" name="nome" size="74" maxlength="70" required="" placeholder="Nome Completo" style="text-transform: uppercase">
            </div>
            <!-- CAMPO SEXO -->
            <div class="form-group col-md-1">
                <label for="sexo">Sexo</label>
                <input type="text" :value="idoso.sexo" class="form-control" name="sexo" id="sexo" size="1" maxlength="1" required placeholder="M/F" style="text-transform: uppercase">
            </div>
            <!-- CAMPO DATA -->
            <div class="form-group col-md-2">
                <label for="nacimento">Data</label>
                <input type="text" :value="idoso.nacimento" class="form-control" name="nacimento" autofocus id="data2" size="7" maxlength="10" required="" placeholder="00/00/000" style="text-transform: uppercase">
            </div>
            <!-- CAMPO CPF -->
            <div class="form-group col-md-2">
                <label for="cpf">CPF</label>
                <input type="text" :value="idoso.cpf" class="form-control" name="cpf" id="cpf" required placeholder="000.000.000-00" style="text-transform: uppercase" maxlength="14">
            </div>
            <!-- CAMPO CEP -->
            <div class="form-group col-md-2">
                <label for="cep">CEP</label>
                <input type="text" :value="idoso.cep" class="form-control cep" name="cep" id="cep" required placeholder="00000-000" style="text-transform: uppercase" @blur="pesquisacep($event);" maxlength="9">
            </div>
            <!-- CAMPO LOGRADOURO -->
            <div class="form-group col-md-5">
                <label for="rua">Logradouro</label>
                <input type="text" :value="idoso.rua" class="form-control" name="rua" id="rua" required placeholder="Endereço" style="text-transform: uppercase">
            </div>
            <!-- CAMPO N° -->
            <div class="form-group col-md-1">
                <label for="numero">N°</label>
                <input type="text" :value="idoso.numero" class="form-control" name="numero" id="numero" required placeholder="N°" maxlength="10" style="text-transform: uppercase">
            </div>
            <!-- CAMPO BAIRRO -->
            <div class="form-group col-md-4">
                <label for="bairro">Bairro</label>
                <input type="text" :value="idoso.bairro" class="form-control" name="bairro" id="bairro" placeholder="Bairro" style="text-transform: uppercase">
            </div>
            <!-- CAMPO CIDADE -->
            <div class="form-group col-md-5">
                <label for="cidade">Cidade</label>
                <input type="text" :value="idoso.cidade" class="form-control" name="cidade" id="cidade" placeholder="Cidade" style="text-transform: uppercase">
            </div>
            <!-- CAMPO ESTADO -->
            <div class="form-group col-md-1">
                <label for="uf">Estado</label>
                <input type="text" :value="idoso.uf" class="form-control" name="uf" id="uf" size="2" maxlength="2" placeholder="UF" style="text-transform: uppercase">
            </div>
            <!-- CAMPO IBGE -->
            <div class="form-group col-md-2">
                <label for="ibge">IBGE</label>
                <input type="text" :value="idoso.ibge" class="form-control" name="ibge" id="ibge" size="15" maxlength="15" placeholder="IBGE" style="text-transform: uppercase">
            </div>
            <!-- CAMPO TELEFONE -->
            <div class="form-group col-md-4">
                <label for="tel">Telefone</label>
                <input type="text" :value="idoso.tel" class="form-control" name="tel" id="tel" size="100" maxlength="100" required placeholder="(00) 0000-0000" style="text-transform: uppercase">
            </div>
            <!-- CAMPO DATA NASCIMENTO -->
            <div class="form-group col-md-2">
                <label class="lab" for="saber">Data Nasc.</label>
                <input type="text" :value="idoso.age" class="form-control" name="saber" id="age" style="text-transform: uppercase" placeholder="Data Nasc." maxlength="50" readonly="readonly">
            </div>
            <!-- CAMPO IDS ANTIGO -->
            <div class="form-group col-md-2">
                <label for="numero">IDS Antigo</label>
                <input type="text" :value="idoso.ids_ant" class="form-control" name="ids_ant" id="ids_ant" style="text-transform: uppercase" placeholder="IDS Antigo" maxlength="50">
            </div>
            <!-- CAMPO OBSERVAÇÕES -->
            <div class="form-group col-md-8">
                <label for="obs">Observações</label>
                <textarea id="obs" :value="idoso.obs" onFocus="aoClicarOb()" onBlur="aoSairOb()" class="form-control" name="obs" rows="1" data-length="120" placeholder="Observações" style="text-transform: uppercase"></textarea>
            </div>
        </div>
    </div>
        <div class="card-footer">
          <button type="submit" id="desabilita" class="btn btn-primary">Editar</button>
        </div>
      </form>
  </div>
  <div class="card direct-chat direct-chat-primary">
    <div class="card-header ui-sortable-handle" style="cursor: move;">
      <h3 class="card-title">RECENTES</h3>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
        <button type="button" class="btn btn-tool" data-card-widget="remove">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="container-fluid" >
        <table class="table table-sm table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Sexo</th>
              <th>CPF</th>
              <th>Nascimento</th>
              <th>CEP</th>
              <th>Rua</th>
              <th>N°</th>
              <th>Cidade</th>
              <th>Bairro</th>
              <th>UF</th>
              <th>Visualizar</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(usuario, index) in usuarios" v-bind:key="index">
                <td>{{usuario.nome}}</td>
                <td>{{usuario.sexo}}</td>
                <td>{{usuario.cpf}}</td>
                <td>{{usuario.nacimento}}</td>
                <td>{{usuario.cep}}</td>
                <td>{{usuario.rua}}</td>
                <td>{{usuario.numero}}</td>
                <td>{{usuario.cidade}}</td>
                <td>{{usuario.bairro}}</td>
                <td>{{usuario.uf}}</td>
                <td>
                    <a class="btn btn-primary btn-sm" href="#" role="button">Visualizar</a>
                </td>
                <td>
                    <a :href="'/editIdoso/'+[usuario.id]" class="btn btn-primary btn-sm" @click="editar(usuario.id)" role="button">Editar</a>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</AppLayout>
</template>
<script>
import Vue from 'vue'
import AppLayout from './Auth/Dashboard.vue'
import { Head } from '@inertiajs/inertia-vue';
import axios from 'axios'
import config from "../config";
axios.create({
    baseURL: config.baseUrl,
});

export default Vue.extend({
  name: 'EditarIdosoPage',
  layout: 'default',
  head: {
    title: 'Idoso',
  },
  props:['csrfToken', 'idoso'],
  data() {
    return {
        nome: '',
        sexo: '',
        nacimento: '',
        cpf: '',
        cep: '',
        rua: '',
        numero: '',
        bairro: '',
        cidade: '',
        uf: '',
        ibge: '',
        tel: '',
        obs: '',
        ids_ant: '',
        age: '',
      usuarios: [],
    }
  },
  methods: {
    async pesquisacep(event) {
      //Nova variável "cep" somente com dígitos.
      var cep = event.target.value.replace(/\D/g, '');
      //Verifica se campo cep possui valor informado.
      if (cep != "") {
          //Expressão regular para validar o CEP.
          var validacep = /^[0-9]{8}$/;
          //Valida o formato do CEP.
          if(validacep.test(cep)) {
            //Preenche os campos com "..." enquanto consulta webservice.
            document.getElementById('rua').value="...";
            document.getElementById('bairro').value="...";
            document.getElementById('cidade').value="...";
            document.getElementById('uf').value="...";
            document.getElementById('ibge').value="...";
        }
        ///get cep via axios
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        if(response.data.erro) {
          alert("CEP não encontrado.")
          document.getElementById('rua').value="";
          document.getElementById('bairro').value="";
          document.getElementById('cidade').value="";
          document.getElementById('uf').value="";
          document.getElementById('ibge').value="";
        } else {
          //Atualiza os campos com os valores da consulta.
          document.getElementById('rua').value=response.data.logradouro;
          document.getElementById('bairro').value=response.data.bairro;
          document.getElementById('cidade').value=response.data.localidade;
          document.getElementById('uf').value=response.data.uf;
          document.getElementById('ibge').value=response.data.ibge;
        }
      }
    },
   getUsuarios() {
      axios.get('/api/getAllCadastro')
      .then(response => {
        this.usuarios = response.data
      })
      .catch(error => {
        console.log(error)
      })
    },
  },
  mounted() {
    this.getUsuarios()
  },
  components: {
    Head,
    AppLayout,
  },
})
</script>
