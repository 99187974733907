<template>
  <AppLayout>

    <Head :title="'Visualizar Perfil ' + title + ' ' + usuario.nome"></Head>
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0">Visualizar {{ title }}</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/home">Home</a></li>
          <li class="breadcrumb-item active">Visualizar</li>
        </ol>
      </div>
    </div>
    <div v-if="usuario.datavalidade && usuario.datavalidade < getDataAtual()" class="tarja-vermelha">
      <span class="texto-tarja">Data Expirada</span>
    </div>

    <div class="media form-row align-items-center">
      <div class="form-row col-10">
        <div class="col-2">
          IDS:
          <input :value="usuario.id" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col-3">
          IDS Antigo:
          <input :value="usuario.id_antigo" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col">
          Cadastrador:
          <input :value="usuario.usuario" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-8">
          Nome:
          <input :value="usuario.nome" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-1">
          Sexo:<input :value="usuario.sexo" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col">
          Idade:
          <input :value="ano" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-7">
          Endereço:<input :value="usuario.rua" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-2">
          N°:
          <input :value="usuario.numero" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-3">
          Tel:
          <input :value="usuario.tel" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-6">
          Bairro:<input :value="usuario.bairro" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-6">
          Cidade:
          <input :value="usuario.cidade" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-1">
          UF:
          <input :value="usuario.uf" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-2">
          CEP:<input :value="usuario.cep" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-3">
          CPF:
          <input :value="usuario.cpf" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-2">
          Data Nasc.:<input :value="usuario.nacimento" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col">
          Data Cadastro:
          <input :value="usuario.created_at" id="age" type="text" class="form-control" placeholder="Sem Resultado"
            style="text-transform: uppercase" readonly="" />
        </div>
        <div class="col col-12">
          Observações:
          <textarea id="obs" onfocus="aoClicarOb()" onblur="aoSairOb()" class="form-control" name="obs" rows="1"
            data-length="120" placeholder="Observações" style="text-transform: uppercase" v-model="usuario.obs"
            readonly=""></textarea>
        </div>
      </div>
      <div v-if="avatar" class="col-2 align-items-center">
        <img :src="'/storage/' + avatar" class="img-fluid" alt="avatars" />
        <div class="mt-4">
          <a href="#" :class="'btn mx-auto d-block btn-' + color" data-toggle="modal" data-target="#modal-foto">Enviar
            foto</a>
        </div>
      </div>
      <div v-else class="col-2 align-items-center">
        <img src="/dist/img/default.png" class="img-fluid" alt="avatars" />
        <div class="mt-4">
          <a href="#" :class="'btn mx-auto d-block btn-' + color" data-toggle="modal" data-target="#modal-foto">Enviar
            foto</a>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <a :href="route + user_id + '/' + title" :class="'btn btn-' + color">Editar</a>
      <a :href="'/pdf/' + user_id + '/' + title" data-print="8397" data-date="20/06/2022" class="btn btn-primary"
        target="_blank" id="imprimir">Imprimir frente</a>
      <a :href="'/pdfVerso/' + user_id + '/' + title" data-print="8397" data-date="20/06/2022" class="btn btn-primary"
        target="_blank" id="imprimir">Imprimir verso</a>
      <a href="#" class="btn btn-info" data-toggle="modal" data-target="#modal-image">Salvar
        documento</a>
    </div>
    <div class="alert alert-danger alert-dismissible mt-4" v-show="alerta" v-if="dataPrint == 0">
      O cidadão(ã) imprimiu o cartão HOJE, período esse, menos de um ano.
    </div>
    <div class="alert alert-success alert-dismissible mt-4" v-show="alerta" v-else-if="dataPrint == 500">
      O cidadão(ã) cadastrado hoje.
    </div>
    <div class="alert alert-danger alert-dismissible mt-4" v-show="alerta" v-else>
      O cidadão(ã) imprimiu o cartão no dia {{ usuario.date_print }}, período
      esse, menos de um ano.
    </div>
    <div class="modal fade" id="modal-default">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div v-if="print == 500">
            <div class="modal-body">
              <div class="mt-4 mb-4 card text-center">
                <div class="card-header">
                  <h1 class="text-success">Atenção!!!</h1>
                </div>
                <div class="card-body">

                </div>
                <div class="card-footer text-muted">
                  <h3>
                    Usuário cadastrado hoje, cartão ainda não foi impresso.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="modal-body">
              <div class="mt-4 mb-4 card text-center">
                <div class="card-header">
                  <h1 class="text-danger">Atenção!!!</h1>
                </div>
                <div class="card-body">
                  <h5 class="card-title text-danger">
                    ALERTA DE TEMPO DE IMPRESSÃO
                  </h5>
                  <h4 class="card-text">
                    O cidadão(ã) já imprimiu o cartão no período de menos de um
                    ano
                  </h4>
                </div>
                <div class="card-footer text-muted">
                  <h3 v-if="dataPrint == 0">Data da última impresão: Hoje!</h3>
                  <h3 v-else>
                    Data da última impresão: {{ dataPrint }} dias atrás,
                    aproximadamente!
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-4">
      <div class="card card-primary">
        <div class="card-header">
          <h4 class="card-title">Documentos</h4>
        </div>
        <div class="container card-body">
          <div v-if="imagem != ''">
            <div class="mx-auto d-flex justify-content-center align-items-center" v-for="(img, index) in imagem"
              :key="index">
              <div id="parent" v-if="isPDF(img.path)" class="bg-info embed-responsive embed-responsive-16by9 mb-5 mt-5">
                <h3>PDF</h3>
                <iframe :src="'/storage/' + [img.path]" title="description" height="500" width="400"></iframe>
                <button class="btn btn-danger" @click="destroy(img.id)">Excluir</button>
              </div>
              <div v-else class="bg-secondary">
                <h3>Imagem</h3>
                <a :href="'/storage/' + [img.path]" data-toggle="lightbox" :data-title="'imagem ' + index"
                  data-gallery="gallery">
                  <img :src="'/storage/' + [img.path]" class="img-fluid" :alt="'imagem ' + index" />
                </a>
                <button class="btn btn-danger position-absolute" @click="destroy(img.id)">Excluir</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal-foto">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <form :action="'/newPhotoUsuario/' + [user_id] + '/' + [title]" method="POST" enctype="multipart/form-data">
            <input type="hidden" name="_token" v-bind:value="csrfToken" />
            <input type="hidden" name="user_id" v-bind:value="user_id" />
            <div class="modal-header">
              <h4 class="modal-title">Nova foto</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                <label>Escolha uma imagens (Somente imagens)</label>
                <div class="file-loading mt-4">
                  <input id="file-es" name="image" type="file" accept="image/*" />
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-foto" data-dismiss="modal">
                Fechar
              </button>
              <button type="submit" class="btn btn-primary">Trocar foto</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal-image">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <form :action="'/photoDocument/' + [usuario.id] + '/' + [title]" method="POST" enctype="multipart/form-data">
            <input type="hidden" name="_token" v-bind:value="csrfToken" />
            <input type="hidden" name="documento_id" v-bind:value="user_id" />
            <div class="modal-header">
              <h4 class="modal-title">Nova foto</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                <label>Escolha uma imagens (Somente imagens)</label>
                <div class="file-loading mt-4">
                  <input id="file-es2" name="image" type="file" accept="image/*,application/pdf" />
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-foto" data-dismiss="modal">
                Fechar
              </button>
              <button type="submit" class="btn btn-primary">Carregar imagem</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import Vue from "vue";
import { Head } from "@inertiajs/inertia-vue";
import AppLayout from "./Auth/Dashboard.vue";
import axios from "axios";
import config from "../config";
axios.create({
  baseURL: config.baseUrl,
});
export default {
  props: [
    "csrfToken",
    "usuario",
    "title",
    "color",
    "ano",
    "token",
    "avatar",
    "print",
    "dataPrint",
    "success",
    "imagem"
  ],
  data() {
    return {
      user_id: this.usuario.id,
      route: "/editar/",
      alerta: false,
    };
  },
  async mounted() {
    this.getDataPrint();
    if (this.dataPrint <= 365) {
      this.alerta = true;
      $("#modal-default").modal("show");
    }

    if (this.dataPrint > 365) {
      $("#modal-default").modal("hide");
      this.alerta = false;
    }
  },
  methods: {
    async destroy(id) {
      if (confirm("Tem certeza de que deseja excluir?")) {
        try {
          this.$inertia.delete(`/documentos/${this.title}/${id}`);
          // Atualizar os dados ou recarregar a página após a exclusão, se necessário
        } catch (error) {
          console.log(error);
          // Lógica de tratamento de erro, se necessário
        }
      }
    },
    isPDF(path) {
      const extension = path.split('.').pop().toLowerCase();
      return extension === 'pdf';
    },
    async getDataPrint() {
      await axios
        .get("/api/dataPrint/" + this.usuario.id + "/" + this.title, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.dataPrint = response.data;
          console.log(this.dataPrint);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataAtual() {
      const dataAtual = new Date();
      const dia = String(dataAtual.getDate()).padStart(2, '0');
      const mes = String(dataAtual.getMonth() + 1).padStart(2, '0');
      const ano = dataAtual.getFullYear();
      return `${ano}-${mes}-${dia}`;
    }
  },
  created() {
    this.getDataPrint();
  },
  components: {
    Head,
    AppLayout,
  },
};
</script>
<style scoped>
.tarja-vermelha {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: transparent;
  /* Vermelho semi-transparente */
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: rotate(-45deg); */
  /* Rotação diagonal */
  pointer-events: none;
  /* Permite interação com elementos abaixo da tarja */
}

.texto-tarja {
  color: rgba(215, 28, 28, 0.575);
  font-size: 34px;
  font-weight: bold;
  text-transform: uppercase;
  transform: rotate(45deg);
  /* Rotação diagonal inversa */
}

.iframe-wrapper {
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 10px;
}

.image-wrapper {
  margin-bottom: 10px;
}

/* Playground by EThaiZone */

#parent>button {
  opacity: 0.3;
  position: relative;
  float: right;
  right: 10px;
  bottom: 35px;
  transition: 0.5s;
}

#parent>button {
  opacity: 1;
}
</style>