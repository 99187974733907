<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0">Editar <span style="font-size: 15px">({{ usuarios.nome }})</span></h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="/home">Home</a></li>
          <li class="breadcrumb-item active">{{ title }}</li>
        </ol>
      </div>
    </div>
    <div :class="'card card-' + color" id="move">
      <div class="card-header">
        <h3 class="card-title">Cartão {{ title }}</h3>
      </div>
      <form :action="route" :method="methodForm">
        <input type="hidden" name="_token" v-bind:value="csrfToken" />
        <div class="card-body">
          <div class="form-row">
            <!-- CAMPO NOME -->
            <div class="form-group col-md-7">
              <label for="nome">Nome</label>
              <input type="text" :value="usuarios.nome" class="form-control" name="nome" size="74" maxlength="70"
                required="" placeholder="Nome Completo" style="text-transform: uppercase" />
            </div>
            <!-- CAMPO SEXO -->
            <div class="form-group col-md-1">
              <label for="sexo">Sexo</label>
              <select class="form-select" v-bind:value="usuarios.sexo" name="sexo" required>
                <option value="M">M</option>
                <option value="F">F</option>
              </select>
            </div>
            <!-- CAMPO DATA -->
            <div class="form-group col-md-2">
              <label for="nacimento">Data</label>
              <input type="text" :value="usuarios.nacimento" class="form-control" name="nacimento" autofocus id="data2"
                size="7" maxlength="10" required="" placeholder="00/00/000" style="text-transform: uppercase" />
            </div>
            <!-- CAMPO CPF -->
            <div class="form-group col-md-2">
              <label for="cpf">CPF</label>
              <input type="text" :value="usuarios.cpf" class="form-control" name="cpf" id="cpf" required
                placeholder="000.000.000-00" style="text-transform: uppercase" maxlength="14" />
            </div>
            <!-- CAMPO CEP -->
            <div class="form-group col-md-2">
              <label for="cep">CEP</label>
              <input type="text" :value="usuarios.cep" class="form-control cep" name="cep" id="cep" required
                placeholder="00000-000" style="text-transform: uppercase" @blur="pesquisacep($event)" maxlength="9" />
            </div>
            <!-- CAMPO LOGRADOURO -->
            <div class="form-group col-md-5">
              <label for="rua">Logradouro</label>
              <input type="text" :value="usuarios.rua" class="form-control" name="rua" id="rua" required
                placeholder="Endereço" style="text-transform: uppercase" />
            </div>
            <!-- CAMPO N° -->
            <div class="form-group col-md-1">
              <label for="numero">N°</label>
              <input type="text" :value="usuarios.numero" class="form-control" name="numero" id="numero" required
                placeholder="N°" maxlength="10" style="text-transform: uppercase" />
            </div>
            <!-- CAMPO BAIRRO -->
            <div class="form-group col-md-4">
              <label for="bairro">Bairro</label>
              <input type="text" :value="usuarios.bairro" class="form-control" name="bairro" id="bairro"
                placeholder="Bairro" style="text-transform: uppercase" />
            </div>
            <!-- CAMPO CIDADE -->
            <div class="form-group col-md-5">
              <label for="cidade">Cidade</label>
              <input type="text" :value="usuarios.cidade" class="form-control" name="cidade" id="cidade"
                placeholder="Cidade" style="text-transform: uppercase" />
            </div>
            <!-- CAMPO ESTADO -->
            <div class="form-group col-md-1">
              <label for="uf">Estado</label>
              <input type="text" :value="usuarios.uf" class="form-control" name="uf" id="uf" size="2" maxlength="2"
                placeholder="UF" style="text-transform: uppercase" />
            </div>
            <!-- CAMPO IBGE -->
            <div v-show="title !== 'Pne'" class="form-group col-md-2" style="display: none;">
              <label for="ibge">IBGE</label>
              <input type="text" class="form-control" name="ibge" id="ibge" size="15" maxlength="15" placeholder="IBGE"
                style="text-transform: uppercase">
            </div>
            <!-- CAMPO ESCOLHER DATA -->
            <div v-show="title === 'Pne'" class="form-group col-md-2">
              <label for="validade">Validade</label>
              <input type="date" class="form-control" :min="minDate" :max="maxDate" name="validade" id="validade"
                style="text-transform: uppercase">
            </div>
            <!-- CAMPO TELEFONE -->
            <div class="form-group col-md-4">
              <label for="tel">Telefone</label>
              <input type="text" :value="usuarios.tel" class="form-control" name="tel" id="tel" size="100" maxlength="100"
                required placeholder="(00) 0000-0000" style="text-transform: uppercase" />
            </div>
            <!-- CAMPO DATA NASCIMENTO -->
            <div class="form-group col-md-2">
              <label class="lab" for="saber">Data Nasc.</label>
              <input type="text" :value="usuarios.age" class="form-control" name="saber" id="age"
                style="text-transform: uppercase" placeholder="Data Nasc." maxlength="50" readonly="readonly" />
            </div>
            <!-- CAMPO IDS ANTIGO -->
            <div class="form-group col-md-2">
              <label for="numero">IDS Antigo</label>
              <input type="text" :value="usuarios.id_antigo" class="form-control" name="ids_ant" id="ids_ant"
                style="text-transform: uppercase" placeholder="IDS Antigo" maxlength="50" />
            </div>
            <!-- CAMPO OBSERVAÇÕES -->
            <div class="form-group col-md-8">
              <label for="obs">Observações</label>
              <textarea id="obs" :value="usuarios.obs" onFocus="aoClicarOb()" onBlur="aoSairOb()" class="form-control"
                name="obs" rows="1" data-length="120" placeholder="Observações"
                style="text-transform: uppercase"></textarea>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button type="submit" id="desabilita" :class="'btn btn-' + color">
            Editar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { format, addYears } from 'date-fns';
export default {
  props: ["csrfToken", "usuarios", "title", "route", "color", "methodForm"],
  data() {
    return {
      minDate: '',
      maxDate: ''
    };
  },
  methods: {
    async pesquisacep(event) {
      //Nova variável "cep" somente com dígitos.
      var cep = event.target.value.replace(/\D/g, "");
      //Verifica se campo cep possui valor informado.
      if (cep != "") {
        //Expressão regular para validar o CEP.
        var validacep = /^[0-9]{8}$/;
        //Valida o formato do CEP.
        if (validacep.test(cep)) {
          //Preenche os campos com "..." enquanto consulta webservice.
          document.getElementById("rua").value = "...";
          document.getElementById("bairro").value = "...";
          document.getElementById("cidade").value = "...";
          document.getElementById("uf").value = "...";
          document.getElementById("ibge").value = "...";
        }
        ///get cep via axios
        const response = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );
        if (response.data.erro) {
          alert("CEP não encontrado.");
          document.getElementById("rua").value = "";
          document.getElementById("bairro").value = "";
          document.getElementById("cidade").value = "";
          document.getElementById("uf").value = "";
          document.getElementById("ibge").value = "";
        } else {
          //Atualiza os campos com os valores da consulta.
          document.getElementById("rua").value = response.data.logradouro;
          document.getElementById("bairro").value = response.data.bairro;
          document.getElementById("cidade").value = response.data.localidade;
          document.getElementById("uf").value = response.data.uf;
          document.getElementById("ibge").value = response.data.ibge;
        }
      }
    },
    getElemento() {
      if (this.title == "Pne") {
        document.getElementById("data2").setAttribute("id", "data3");
      }
    },
    handleDateChange(date) {
      if (!date) {
        const currentDate = new Date();
        const futureDate = addYears(currentDate, 5);
        this.$refs.dateInput.value = format(futureDate, 'yyyy-MM-dd');
      }
    }
  },
  mounted() {
    console.log("Componente montado formEditar.");
    this.getElemento();
    const currentDate = new Date();
    this.minDate = format(currentDate, 'yyyy-MM-dd');

    const futureDate = addYears(currentDate, 5);
    this.maxDate = format(futureDate, 'yyyy-MM-dd');
  },
};
</script>