<template>
  <AppLayout>

    <Head title="Cartões Solicitados"> </Head>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Todos os registros</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <table id="example1" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Nascimento</th>
              <th>Data solicitado</th>
              <th>Tipo</th>
              <th scope="row">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(solicitado, index) in allSolicitados" :key="index">
              <td class="col">
                {{ solicitado.nome }}
              </td>
              <td>
                {{ solicitado.cpf }}
              </td>
              <td>
                {{ solicitado.nascimento }}
              </td>
              <td>
                {{ solicitado.created_at }}
              </td>
              <td class="text-uppercase">
                {{ solicitado.tipo }}
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <!-- <button class="btn btn-primary btn-sm" @click="buscarImage(solicitado.image, solicitado.id)"
                  data-toggle="modal" data-target="#modal-default">Fotos</button>
                <button @click="atender(solicitado.id)" class="btn btn-primary btn-sm">Atender</button>
                <button data-toggle="modal" data-target="#modal-recusar" class="btn btn-danger btn-sm">Recusar</button> -->
                <!-- <div class="btn-group" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-secondary">Left</button>
                  <button type="button" class="btn btn-secondary">Middle</button>
                  <button type="button" class="btn btn-secondary">Right</button>
                </div> -->
                <div class="btn-group">
                  <button type="button" @click="buscarImage(solicitado.image, solicitado.id, solicitado.image_from)"
                    data-toggle="modal" data-target="#modal-default" class="btn btn-info">Fotos</button>
                  <button type="button" @click="atender(solicitado.id)" class="btn btn-success">Atender</button>
                  <button type="button" data-toggle="modal"
                    @click="recusar(solicitado.id, solicitado.nome, solicitado.email)" data-target="#modal-recusar"
                    class="btn btn-danger">Recusar</button>
                  <a class="btn btn-success" :href="'/painel/' + solicitado.id">Painel</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal fade" id="modal-default" ref="vuemodal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Fotos {{ idUser }}</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="magnify" ref="magnify" @mouseleave="mouseHoverHide()">
              <div v-if="image_from === 'api'" class="magnifier" ref="magnifier"
                :style="'background-image:url(' + image + ');'">
              </div>
              <div v-else class="magnifier" ref="magnifier" :style="'background-image:url(storage/' + image + ');'">
              </div>
              <div v-if="image">
                <div v-if="image_from === 'api'" class="magnified" ref="magnified" @mouseover="magnified()"
                  @mousemove="mover($event)">
                  <img :src="image" :alt="image">
                </div>
                <div v-else class="magnified" ref="magnified" @mouseover="magnified()" @mousemove="mover($event)">
                  <img :src="'storage/' + image" :alt="image">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Fechar
            </button>
            <button @click="atender(idUser), closeModal()" class="btn btn-primary">Atender</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal-recusar">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Mensagem</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form action="/recusar" method="POST">
              <div class="card">
                <div class="card-body row">
                  <div class="col text-center d-flex align-items-center justify-content-center">
                    <div class="">
                      <h2><strong>SESDEM</strong></h2>
                      <p class="lead mb-5">
                        Rua Felipe Camarão, 934 - 960 - Centro, Mossoró - RN, 59603-340<br>
                        E-mail: atendimentotransito@prefeiturademossoro.com.br
                        Fone: 84 3315-5008 / 84 98827-8902 (WhatsApp).
                      </p>
                    </div>
                  </div>
                  <div class="col">
                    <input type="hidden" name="_token" v-bind:value="csrfToken" />
                    <input type="hidden" :value="idUser" id="id" name="id" class="form-control" />
                    <div class="form-group">
                      <label for="nome">Nome</label>
                      <input type="text" :value="name" id="nome" name="nome" required class="form-control" />
                      <div class="invalid-feedback" v-if="errors.nome">{{ errors.nome }}</div>
                    </div>
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input type="email" id="email" name="email" required :value="email" class="form-control" />
                      <div class="invalid-feedback" v-if="errors.email">{{ errors.email }}</div>
                    </div>
                    <div class="form-group">
                      <label for="assunto">Assunto</label>
                      <input type="text" id="assunto" name="assunto" required class="form-control" />
                      <div class="invalid-feedback" v-if="errors.assunto">{{ errors.assunto }}</div>
                    </div>
                    <div class="form-group">
                      <label for="mensagem">Mensagem</label>
                      <textarea id="mensagem" name="mensagem" required class="form-control" rows="4"></textarea>
                      <div class="invalid-feedback" v-if="errors.mensagem">{{ errors.mensagem }}</div>
                    </div>
                    <div class="form-group">
                      <input type="submit" class="btn btn-primary" value="Envia menssagem">
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import Vue from "vue";
import { Head } from "@inertiajs/inertia-vue";
import AppLayout from "./Auth/Dashboard.vue";
import axios from "axios";
import config from "../config";
axios.create({
  baseURL: config.baseUrl,
});

export default {
  props: {
    errors: Object,
    csrfToken: String,
    tipo: String
  },
  data() {
    return {
      allSolicitados: [],
      image: '',
      image_from: null,
      name: '',
      email: '',
      idUser: '',
      imgPosition: {
        top: 0,
        left: 0,
      },
      imgHeight: 0,
      imgWidth: 0,
      show: false,
      backgroundPosition: 0,
      top: 0,
      left: 0,
    };
  },
  mounted() {
    this.getSolicitados();
    this.mouseHoverHide();
  },
  methods: {
    async getSolicitados() {
      const response = await axios.get("api/allSolicitado");
      this.allSolicitados = response.data;
      return response.data;
    },
    async atender(id) {
      if (confirm('Um e-email será enviado ao solicitante confirmando a conclusão do cartão, CONFIRMAR?')) {
        const response = await axios.post("api/atender/" + id);
        this.getSolicitados();
        return response.data;
      }
    },
    magnified() {
      this.imgPosition = {
        top: this.$refs.magnify.getBoundingClientRect().top,
        left: this.$refs.magnify.getBoundingClientRect().left
      };
      this.imgWidth = this.$refs.magnified.getBoundingClientRect().width;
      this.imgHeight = this.$refs.magnified.getBoundingClientRect().height;
      // this.show = true;
      this.$refs.magnifier.style.display = "block";
      this.mover(this.$refs.magnified);
    },
    mover(e) {
      let posX = e.pageX - this.imgPosition.left;
      let posY = e.pageY - this.imgPosition.top;
      let percX = (posX / this.imgWidth) * 100;
      let percY = (posY / this.imgHeight) * 100;
      let perc = percX + "% " + percY + "%";
      this.top = posY;
      this.left = posX;
      this.backgroundPosition = perc;
      console.log(this.$refs.magnifier.style.display)
      this.zoomCss();
    },
    zoomCss() {
      this.$refs.magnifier.style.top = this.top + "px";
      this.$refs.magnifier.style.left = this.left + "px";
      this.$refs.magnifier.style.backgroundPosition = this.backgroundPosition;
    },
    mouseHoverHide() {
      // this.show = false;
      this.$refs.magnifier.style.display = "none";
    },
    buscarImage(imagem, ids, imageFrom) {
      this.image = imagem;
      this.idUser = ids;
      this.image_from = imageFrom;

    },
    closeModal() {
      // this.$refs.vuemodal.style.display = "none"
      $('#modal-default').modal('hide')
    },
    recusar(id, n, em) {
      this.idUser = id;
      this.name = n;
      this.email = em;

    },
    // recusar() {
    //   this.$inertia.post('/recusar', this.form);
    //   $('#modal-recusar').modal('hide')
    // },
  },
  components: {
    Head,
    AppLayout,
  },
};
</script>
<style scoped>
.magnify {
  position: relative;
  /* transform: translate(-50%, -50%);
  top: 50%;
  left: 50%; */
  display: inline-block;
}

.magnify .magnified {
  display: block;
  z-index: 10;
  margin: auto;
  width: 600px;
  height: 360px;
  border: 5px solid #fff;
}

.magnify .magnifier {
  height: 150px;
  width: 150px;
  position: absolute;
  z-index: 20;
  border: 4px solid white;
  background-size: 1000%;
  background-repeat: no-repeat;
  margin-left: -100px !important;
  margin-top: -100px !important;
  pointer-events: none;
}

/* 
.magnifier {
  top: v-bind(top);
  left: v-bind(left);
  background-position: v-bind(backgroundPosition)
} */

img {
  width: 100%;
  height: 100%;
}
</style>