<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Usuários do sistema {{ getId }}</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <table id="example2" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>CPF</th>
              <th>Tipo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(allUser, index) in allUsers" :key="index">
              <td>
                {{ allUser.name }}
              </td>
              <td>
                {{ allUser.email }}
              </td>
              <td>
                {{ allUser.cpf }}
              </td>
              <td>
                {{ allUser.permission }}
              </td>
              <td>
                <div v-if="tipo === 'admin' || tipo === 'chefe'">
                  <a href="javascript:void(0)" class="btn bg-gradient-primary btn-sm" data-toggle="modal"
                    data-target="#modal-recusar" @click="getIdUser(allUser.id, allUser.name)">
                    <i class="fa fa-edit" data-toggle="tooltip" data-placement="top" title="Editar"></i>
                    Editar
                  </a>
                </div>
                <div v-else>
                  Sem ações
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal fade" id="modal-recusar">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Atualizar senha: <span class="text-uppercase">{{ getUser }}</span></h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md">
              <form :action="'/editar-password/' + getId" method="post">
                <input type="hidden" name="_token" v-bind:value="csrfToken" />
                <label for="nome">Nova senha</label>

                <input type="password" class="form-control" name="password">
                <div class="form-group" v-if="tipo === 'admin'">
                  <label>Tipo</label>
                  <select class="form-control" name="permission">
                    <option value="">Escolha uma opção</option>
                    <option value="admin">Administrador</option>
                    <option value="user">Usuário</option>
                    <option value="chefe">Chefe</option>
                    <option value="cadastrador">Cadastrador</option>
                    <option value="atendente">Atendente</option>
                  </select>
                </div>
                <div class="form-group mt-3">
                  <input type="submit" class="btn btn-primary" value="Trocar senha">
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "../config";
axios.create({
  baseURL: config.baseUrl,
});
export default {
  props: ["tipo", "csrfToken"],
  data() {
    return {
      allUsers: [],
      getId: '',
      getUser: '',
    };
  },
  methods: {
    getAllUsers() {
      axios
        .get("api/getAllUsers")
        .then((response) => {
          this.allUsers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getIdUser(event, name) {
      this.getId = event;
      this.getUser = name;
    }
  },
  mounted() {
    this.getAllUsers();
    this.getIdUser();
  },
  created() {
    console.log("Component created DataTableUser.");
  },
};
</script>