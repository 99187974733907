<template>
    <AppLayout>

        <Head title="PNE"></Head>
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0">PNE</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/home">Home</a></li>
                    <li class="breadcrumb-item active">PNE</li>
                </ol>
            </div>
        </div>
        <!-- <div v-if="$page.props.sucesso">
            <div v-for="(message, key) in $page.props.sucesso" :key="key"
                class="alert alert-success alert-dismissible fade show" role="alert">
                {{ message }}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div> -->
        <div v-if="$page.props.sucesso" class="alert alert-success alert-dismissible fade show" role="alert">
            <ul>
                <li v-for="(message, index) in $page.props.sucesso" :key="index">{{ message }}</li>
            </ul>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <formCard v-bind:color="color" v-bind:csrfToken="csrfToken" v-bind:titleCard="titleCard"
            v-bind:actionRoute="actionRoute" v-bind:methodForm="methodForm" v-bind:title="title" />
    </AppLayout>
</template>
<script>
import AppLayout from './Auth/Dashboard.vue';
import { Head } from "@inertiajs/inertia-vue";
import formCard from '../components/FormCard.vue'

export default {
    props: ['csrfToken', 'title', 'errors'],
    data() {
        return {
            idoso: {
                nome: '',
                sexo: '',
                nacimento: '',
                cpf: '',
                cep: '',
                rua: '',
                numero: '',
                bairro: '',
                cidade: '',
                uf: '',
                ibge: '',
                tel: '',
                obs: '',
                ids_ant: '',
                age: 'nada',
            },
            titleCard: 'Cartão PNE',
            actionRoute: '/cadPne',
            methodForm: 'POST',
            color: 'warning',
            route: '/editar/',
        }
    },
    components: {
        formCard,
        Head,
        AppLayout,
    }
}
</script>
