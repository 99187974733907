<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Todos os registros {{ title }}</h3>
            </div>
            <div class="card-body">
                <table id="example1" class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Nome</th>
                            <th>CPF</th>
                            <th>Nascimento</th>
                            <th v-if="title === 'Pne'">Validade</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(usuario, index) in usuarios" :key="index">
                            <td>
                                {{ usuario.id }}
                            </td>
                            <td>
                                {{ usuario.nome.toUpperCase() }}
                            </td>
                            <td>
                                {{ usuario.cpf }}
                            </td>
                            <td>
                                {{ usuario.nacimento }}
                            </td>
                            <td v-if="title === 'Pne'">
                                {{ formatarData(usuario.datavalidade) }}
                            </td>
                            <td>
                                <a class="btn btn-primary btn-sm" data-toggle="tooltip" data-placement="top"
                                    :title="'Contém ' + contarFotos(imagens, usuario.id) + ' foto(s) de documento(s)'"
                                    :href="'/visualizar/' + usuario.id + '/' + title" role="button">
                                    Visualizar
                                    <span class="badge badge-light balloon-badge"
                                        v-if="contarFotos(imagens, usuario.id) > 0">
                                        {{ contarFotos(imagens, usuario.id) }}
                                    </span>
                                </a>
                                <a :href="route + usuario.id + '/' + title" class="btn btn-primary btn-sm"
                                    role="button">Editar</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    props: ['usuarios', 'title', 'route', 'imagens'],
    methods: {
        contarFotos(imagens, usuarioId) {
            // const fotosUsuario = imagens.filter(img => img.usu_cadastro_id === usuarioId);
            const fotosUsuario = imagens.filter(img => img.usu_cadastro_id === usuarioId || img.usu_cadastro_pne_id === usuarioId);
            return fotosUsuario.length;
        },
        formatarData(data) {
            if (!data) {
                return 'Não informado';
            }
            const dataFormatada = moment(data).format('DD/MM/YYYY');
            return dataFormatada;
        },
    },
}
</script>
<style scoped>
.balloon-badge {
    position: relative;
    display: inline-block;
    padding: 5px 5px;
    font-size: 14px;
    font-weight: bold;
    color: #007bff;
    background-color: #fff;
    border-radius: 20px;
    margin-left: 5px;
    cursor: pointer;
}

.linha-vermelha {
    background-color: #ac5a5a;
    color: white;
}
</style>