<template>
  <AppLayout>

    <Head title="Perfil"></Head>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Perfil</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item active">Perfil</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <!-- Profile Image -->
            <div class="card card-primary card-outline">
              <div class="card-body box-profile">
                <div class="text-center">
                  <img class="profile-user-img img-fluid img-circle" :src="'/storage/' + user.avatar"
                    alt="User profile picture" />
                </div>
                <h3 class="profile-username text-center">
                  {{ user.name.toUpperCase() }}
                </h3>
                <p class="text-muted text-center">{{ user.email }}</p>
                <ul class="list-group list-group-unbordered mb-3">
                  <li class="list-group-item">
                    <b>Cadastros relizados</b>
                    <a class="float-right">{{ countCad }}</a>
                  </li>
                  <li class="list-group-item">
                    <b>Idoso</b> <a class="float-right">{{ countIdoso }}</a>
                  </li>
                  <li class="list-group-item">
                    <b>PNE</b> <a class="float-right">{{ countPne }}</a>
                  </li>
                </ul>
                <a href="#" class="btn btn-primary btn-block" data-toggle="modal" data-target="#modal-default"><b>Trocar
                    foto</b></a>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="card">
              <div class="card-header p-2">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a class="nav-link active" href="#settings" data-toggle="tab">Senha</a>
                  </li>
                </ul>
              </div>
              <div class="card-body">
                <div class="tab-content">
                  <div class="active tab-pane" id="settings">
                    <form :action="'/newPassword/' + [user.id]" method="POST" class="form-horizontal"
                      enctype="multipart/form-data">
                      <input type="hidden" name="_token" v-bind:value="csrfToken" />
                      <div class="form-group row">
                        <label for="senha" class="col-sm-2 col-form-label">Senha</label>
                        <div class="col-sm-10">
                          <input type="password" name="password" class="form-control" id="senha"
                            placeholder="Nova senha" />
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="offset-sm-2 col-sm-10">
                          <button type="submit" class="btn btn-info">
                            Trocar senha
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="modal fade" id="modal-default">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <form :action="'/newPhoto/' + [user.id]" method="POST" enctype="multipart/form-data">
            <input type="hidden" name="_token" v-bind:value="csrfToken" />
            <div class="modal-header">
              <h4 class="modal-title">Nova foto</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                <label>Escolha uma imagens (Somente imagens)</label>
                <div class="file-loading mt-4">
                  <input id="file-es" name="image" type="file" accept="image/*" />
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" class="btn btn-default" data-dismiss="modal">
                Fechar
              </button>
              <button type="submit" class="btn btn-primary">Trocar foto</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue';
import AppLayout from './Auth/Dashboard.vue';
export default {
  props: ["user", "csrfToken", "countCad", "countIdoso", "countPne"],
  mounted() {
    console.log("Component mounted.");
  },
  data() {
    return {
      image: "",
    };
  },
  components: {
    Head,
    AppLayout,
  },
};
</script>
