<template>
  <AppPainel>

    <Head title="Home"></Head>

    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0">Home</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item active">Home</li>
        </ol>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Atualização 16 de fev 2024 23:45</div>
          <div class="card-body">
            <p>
              Durante a última atualização realizada em 16 de fevereiro de 2024 às 23:00, implementamos diversas melhorias
              no sistema. Destacamos as principais atualizações abaixo:
            </p>
            <ul>
              <li><strong>Autenticação de Rotas:</strong> Reforçamos a segurança do sistema atualizando as autenticações
                em diversas rotas, garantindo que apenas usuários autorizados tenham acesso.</li>
              <li><strong>Integrações com API apicartao.mossoro.rn.gov.br:</strong> Aprimoramos as integrações com a API
                apicartao.mossoro.rn.gov.br, proporcionando uma comunicação mais eficiente e confiável.</li>
              <li><strong>Cache de Imagens:</strong> Implementamos um sistema de cache de imagens para otimizar o
                carregamento e a exibição de imagens, proporcionando uma experiência mais rápida aos usuários.</li>
              <li><strong>Solicitações de Cartão mais Dinâmicas:</strong> Tornamos o processo de solicitação de cartão
                mais dinâmico, proporcionando uma experiência aprimorada aos usuários durante o processo de solicitação.
              </li>
              <!-- Adicione outras atualizações conforme necessário -->
            </ul>
            <p>
              Essas atualizações visam melhorar a segurança, desempenho e usabilidade do sistema. Agradecemos por sua
              compreensão e estamos à disposição para mais informações, reclamações e feedback.
            </p>
            <p>
              Para dúvidas ou suporte técnico, entre em contato com o desenvolvedor responsável:
              <br>
              Josenildo Tiago:
              <br>
              WhatsApp: [84 99808-7950]
            </p>
          </div>
        </div>
      </div>
    </div>
  </AppPainel>
</template>
<script>
import Vue from "vue";
import AppPainel from "./Auth/Dashboard.vue";
import { Head } from "@inertiajs/inertia-vue";
export default {
  data() {
    return {
    }
  },
  components: {
    Head,
    AppPainel,
  },
};
</script>
