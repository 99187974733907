<template>
  <button
    :type="type"
    :class="'btn ' + btnColor + ' ' + (btnSize ? btnSize : '')"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  props: ["btnColor", "title", "btnSize", "type"],
  mounted() {
    console.log("btns");
  },
};
</script>
