<template>
    <AppLayout>

        <Head :title="'Visualizar ' + title"></Head>



        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0">Visualizar {{ title }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/home">Home</a></li>
                    <li class="breadcrumb-item active">{{ title }}</li>
                </ol>
            </div>
        </div>
        <div class="row position-relative">
            <div class="form-inline my-2 pb-3 my-lg-0">
                <input class="form-control mr-sm-2" v-model="frase" @keyup="getUsuarios" type="search" placeholder="Buscar"
                    aria-label="Search">
                <button :class="'btn btn-outline-' + color + ' my-2 my-sm-0'" type="submit">Buscar</button>
            </div>
            <div v-if="isLoading" class="overlay">
                <div class="loader-wrapper">
                    <div class="loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <p>Aguarde...</p>
                </div>
            </div>
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Nascimento</th>
                        <th v-if="title === 'Pne'">Validade</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(usuario, index) in usuarios.data" :key="index">
                        <td>{{ usuario.id }}</td>
                        <td>{{ usuario.nome.toUpperCase() }}</td>
                        <td>{{ usuario.cpf }}</td>
                        <td>{{ usuario.nacimento }}</td>
                        <td v-if="title === 'Pne'">{{ formatarData(usuario.datavalidade) }}</td>
                        <td>
                            <a class="btn btn-primary btn-sm" data-toggle="tooltip" data-placement="top"
                                :title="'Contém ' + contarFotos(imagens, usuario.id) + ' foto(s) de documento(s)'"
                                :href="'/visualizar/' + usuario.id + '/' + title" role="button">
                                Visualizar
                                <span class="badge badge-light balloon-badge" v-if="contarFotos(imagens, usuario.id) > 0">
                                    {{ contarFotos(imagens, usuario.id) }}
                                </span>
                            </a>
                            <a :href="route + usuario.id + '/' + title" class="btn btn-primary btn-sm"
                                role="button">Editar</a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation example" v-if="pagination">
                <ul class="pagination">
                    <li class="page-item" v-for="(link, index) in pagination.links" :key="index"
                        :class="{ 'active': link.active, 'disabled': !link.url && !link.active }">
                        <a class="page-link" href="#" v-if="link.url" @click.prevent="getPage(link.url)">
                            {{ formatLabel(link.label) }}
                        </a>
                        <span class="page-link" v-else>
                            {{ formatLabel(link.label) }}
                        </span>
                    </li>
                </ul>
            </nav>
        </div>

    </AppLayout>
</template>

<script>
import Vue from 'vue';
import { Head } from "@inertiajs/inertia-vue";
import AppLayout from './Auth/Dashboard.vue';
import dataTable from '../components/DataTable';
import axios from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';

export default {
    props: ['csrfToken', 'title', 'imagens'],
    data() {
        return {
            titleCard: 'Cartão Idoso',
            actionRoute: '/cadIdoso',
            methodForm: 'POST',
            color: 'info',
            route: '/editar/',
            age: 'age',
            frase: '',
            page: 1,
            usuarios: {},
            totalPages: 0,
            pagination: null,
            isLoading: false,
            color: 'success',
        };
    },
    methods: {
        getPage(url) {
            const urlParts = url.split('?');
            if (urlParts.length > 1) {
                const params = new URLSearchParams(urlParts[1]);
                const page = params.get('page');
                if (page) {
                    this.page = parseInt(page);
                    this.getUsuariosSearchWithPage();
                }
            }
        },
        formatarData(data) {
            if (!data) {
                return 'Não informado';
            }
            const dataFormatada = moment(data).format('DD/MM/YYYY');
            return dataFormatada;
        },
        formatLabel(label) {
            return label.replace('&laquo;', '').replace('&raquo;', '');
        },
        contarFotos(imagens, usuarioId) {
            const fotosUsuario = imagens.filter(img => img.usu_cadastro_id === usuarioId || img.usu_cadastro_pne_id === usuarioId);
            return fotosUsuario.length;
        },
        async getUsuarios() {
            const debouncedGetUsuarios = debounce(async () => {
                try {
                    this.isLoading = true; // Exibe o spinner antes da chamada Axios
                    const response = await axios.get(`api/searche/${this.title}?search=${this.frase}`);
                    this.usuarios = response.data;
                    this.totalPages = response.data.last_page;
                    this.pagination = response.data;
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isLoading = false; // Oculta o spinner após a chamada Axios
                }
            }, 1000);
            debouncedGetUsuarios();
        },
        async getUsuariosSearch() {
            const debouncedGet = debounce(async () => {
                try {
                    this.isLoading = true; // Exibe o spinner antes da chamada Axios
                    const response = await axios.get(`api/searche/${this.title}?page=${this.page}`);
                    this.usuarios = response.data;
                    this.totalPages = response.data.last_page;
                    this.pagination = response.data;
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isLoading = false; // Oculta o spinner após a chamada Axios
                }
            }, 1000);
            debouncedGet();
        },
        async getUsuariosSearchWithPage() {
            const debouncedGet = debounce(async () => {
                try {
                    this.isLoading = true; // Exibe o spinner antes da chamada Axios
                    const response = await axios.get(`api/searche/${this.title}?search=${this.frase}&page=${this.page}`);
                    this.usuarios = response.data;
                    this.totalPages = response.data.last_page;
                    this.pagination = response.data;
                } catch (error) {
                    console.log(error);
                } finally {
                    this.isLoading = false; // Oculta o spinner após a chamada Axios
                }
            }, 1000);

            debouncedGet();
        },
        changePage(page) {
            if (page < 1 || page > this.totalPages) {
                return;
            }
            this.page = page;
            this.getUsuariosSearch();
        },
    },
    mounted() {
        this.getUsuariosSearch();
        if (this.title === 'Pne') {
            this.color = 'warning';
        } else {
            this.color = 'info';
        }
    },
    components: {
        dataTable,
        Head,
        AppLayout,
    },
};
</script>
<style scoped>
p {
    font-size: 1em;
    font-weight: 300;
    margin-top: 5px;
    letter-spacing: 1px;
    color: #000;
    /* text-shadow: 0 0 2px #fff; */
    text-shadow: 1px 0 #fff, -1px 0 #fff, 0 1px #fff, 0 -1px #fff,
        1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
}

.loader-wrapper {
    position: absolute;
    z-index: 10;
    /* align-items: center;
    justify-content: center; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #164fa0;
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #1f16a0 transparent transparent transparent;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Cor de fundo com opacidade */
    z-index: 9999;
    /* Garanta que o overlay esteja acima de outros elementos */
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>