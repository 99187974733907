<template>
  <AppLayout>
    <Head :title="'Editar ' + usuarios.nome"></Head>
    <formEditar
      v-bind:usuarios="usuarios"
      v-bind:color="color"
      v-bind:csrfToken="csrfToken"
      v-bind:title="title"
      v-bind:route="route"
      v-bind:methodForm="methodForm"
    />
  </AppLayout>
</template>
<script>
import Vue from "vue";
import AppLayout from "./Auth/Dashboard.vue";
import { Head } from "@inertiajs/inertia-vue";
import formEditar from "../components/FormEditar";
import axios from "axios";

export default {
  props: ["csrfToken", "usuarios", "title", "route", "color"],
  data() {
    return {
      methodForm: "POST",
    };
  },
  components: {
    formEditar,
    Head,
    AppLayout,
  },
};
</script>