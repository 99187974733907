<template>
    <GuestLayout>

        <Head title="Solicita Cartão"></Head>
        <div class="container col-11 col-md-9" id="form-container">
            <div class="row gx-5">
                <div class="col-md-6">
                    <h2>Solicite seu cartão</h2>
                    <form action="solicitaCartao" method="POST" enctype="multipart/form-data">
                        <input type="hidden" name="_token" v-bind:value="csrfToken">
                        <div class="mb-3">
                            <label for="nome" class="form-label">Nome</label>
                            <input type="text" class="form-control" name="nome" id="nome" placeholder="Nome completo">
                        </div>
                        <div class="mb-3">
                            <label for="cpf" class="form-label">CPF</label>
                            <input v-if="errors.cpf" type="text" class="form-control is-invalid" name="cpf" id="cpf"
                                placeholder="000.000.000-00" @blur="mascara($event);" maxlength="14" autocomplete="off">
                            <input v-else type="text" class="form-control" name="cpf" id="cpf"
                                placeholder="000.000.000-00" @blur="mascara($event);" maxlength="14" autocomplete="off">
                            <div class="invalid-feedback" v-if="errors.cpf">{{ errors.cpf }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="date" class="form-label">Data de nascimento</label>
                            <input v-if="errors.date" type="date" class="form-control is-invalid" id="date" name="date"
                                placeholder="00/00/0000">
                            <input v-else type="date" class="form-control" id="date" name="date"
                                placeholder="00/00/0000">
                            <div class="invalid-feedback" v-if="errors.date">{{ errors.date }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="telefone" class="form-label">Telefone</label>
                            <input v-if="errors.telefone" type="text" name="telefone" class="form-control is-invalid"
                                id="tel" placeholder="(00) 0000-0000" @blur="mask($event, mphone);">
                            <input v-else type="text" name="telefone" class="form-control" id="tel"
                                placeholder="(00) 0000-0000" @blur="mask($event, mphone);">
                            <div class="invalid-feedback" v-if="errors.telefone">{{ errors.telefone }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label is-invalid">E-mail (Para
                                contato)</label>
                            <input v-if="errors.email" type="email" class="form-control" name="email" id="email"
                                placeholder="nome@email.com">
                            <input v-else type="email" class="form-control" name="email" id="email"
                                placeholder="nome@email.com">
                            <div class="invalid-feedback" v-if="errors.email">{{ errors.email }}</div>
                        </div>
                        <div class="mb-3">
                            <label for="mensagem" class="form-label">Mensagem (Não obrigatório)</label>
                            <textarea class="form-control" name="mensagem" id="mensagem" rows="3"></textarea>
                        </div>
                        <div class="mb-3">
                            <label>Escolha foto do documento (Somente imagens)</label>
                            <div class="file-loading mt-4">
                                <input id="file-es" name="image[]" multiple data-theme="fas" type="file"
                                    accept="image/*" />
                            </div>
                        </div>
                        <input type="submit" class="btn btn-primary" value="Cadastrar">
                    </form>
                </div>
                <div class="col-md-6">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <img src="dist/img/Register1234.png" alt="Tela de registro" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </GuestLayout>
</template>
<script>
import Vue from "vue";
import GuestLayout from '../layouts/GuestLayout.vue';
import { Head } from "@inertiajs/inertia-vue";
require('../../../public/css/style.css');
// require('../../../public/js/main.js');

export default {
    props: ["csrfToken", "errors"],
    methods: {
        mascara(event) {
            var v = i.value;

            if (isNaN(v[v.length - 1])) {
                // impede entrar outro caractere que não seja número
                i.value = v.substring(0, v.length - 1);
                return;
            }

            i.setAttribute("maxlength", "14");
            if (v.length == 3 || v.length == 7) i.value += ".";
            if (v.length == 11) i.value += "-";
        },
        mask(event, mphone) {
            setTimeout(function () {
                var v = mphone(o.value);
                if (v != o.value) {
                    o.value = v;
                }
            }, 1);
            mphone(function (v) {
                var r = v.replace(/\D/g, "");
                r = r.replace(/^0/, "");
                if (r.length > 10) {
                    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
                } else if (r.length > 5) {
                    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
                } else if (r.length > 2) {
                    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
                } else {
                    r = r.replace(/^(\d*)/, "($1");
                }
                return r;
            })
        }

    },
    mounted() {
        console.log("Component mounted Home. ");
    },
    components: {
        Head,
        GuestLayout,
    },
};
</script>