import Vue from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue";
const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Cartao";
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}`),
    setup({ el, App, props, plugin }) {
        Vue.use(plugin);

        new Vue({
            render: (h) => h(App, props),
        }).$mount(el);
    },
});
