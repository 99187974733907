<template>
    <div>
        <!-- Preloader -->
        <div class="preloader flex-column justify-content-center align-items-center">
            <img class="animation__wobble" src="/dist/img/logoprefeitura.png" alt="Prefeitura de Mossoró" height="200"
                width="230">
            <h4 class="pt-2">Carregando dados, por favor aguarde...</h4>
        </div>
        <!-- Navbar -->
        <nav class="main-header navbar navbar-expand navbar-white navbar-light">
            <!-- Left navbar links -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
                </li>
                <li class="nav-item d-none d-sm-inline-block">
                    <a href="/home" class="nav-link">Home</a>
                </li>
                <li class="nav-item d-none d-sm-inline-block">
                    <a href="/idoso" class="nav-link">Idoso</a>
                </li>
                <li class="nav-item d-none d-sm-inline-block">
                    <a href="/pne" class="nav-link">PNE</a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <div class="navbar-search-block">
                        <form class="form-inline">
                            <div class="input-group input-group-sm">
                                <input class="form-control form-control-navbar" type="search" placeholder="Search"
                                    aria-label="Search">
                                <div class="input-group-append">
                                    <button class="btn btn-navbar" type="submit">
                                        <i class="fas fa-search"></i>
                                    </button>
                                    <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                        <i class="fas fa-expand-arrows-alt"></i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
                        <i class="fas fa-th-large"></i>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="/home" class="brand-link">
                <img src="/dist/img/logo2.png" alt="Prefeitura de Mossoró" class="brand-image img-circle elevation-3"
                    style="opacity: .8">
                <span class="brand-text font-weight-light">CARTÃO</span>
            </a>
            <div class="sidebar">
                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div class="image">
                        <!-- @if(Auth::user()->avatar) -->
                        <img :src="'/storage/' + user.avatar" class="img-circle elevation-2" alt="User Image">
                        <!-- @else -->
                        <!-- @endif -->
                    </div>
                    <div class="info">
                        <a href="/perfil" class="d-block">{{ user.name.toUpperCase() }}</a>
                    </div>
                </div>
                <div class="form-inline">
                    <div class="input-group" data-widget="sidebar-search">
                        <input class="form-control form-control-sidebar" type="search" placeholder="Search"
                            aria-label="Search">
                        <div class="input-group-append">
                            <button class="btn btn-sidebar">
                                <i class="fas fa-search fa-fw"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Sidebar Menu -->
                <nav class="mt-2">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li class="nav-item menu-open">
                            <a href="#" class="nav-link active">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Páginas
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview">
                                <li class="nav-item">
                                    <a href="/idoso" class="nav-link " :class="{ 'active': $page.url === '/idoso' }">
                                        <i class="fa fa-id-card nav-icon"></i>
                                        <p>Cad. Idoso</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="/pne" class="nav-link " :class="{ 'active': $page.url === '/pne' }">
                                        <i class="fa fa-id-card nav-icon"></i>
                                        <p>Cad. Pne</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="/view_idoso" class="nav-link "
                                        :class="{ 'active': $page.url === '/view_idoso' }">
                                        <i class="fa fa-file-image nav-icon"></i>
                                        <p>Visualizar Cad. Idosos</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="/view_pne" class="nav-link " :class="{ 'active': $page.url === '/view_pne' }">
                                        <i class="fa fa-file-image nav-icon"></i>
                                        <p>Visualizar Cad. PNE</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="/perfil" class="nav-link " :class="{ 'active': $page.url === '/perfil' }">
                                        <i class="fa fa-user nav-icon"></i>
                                        <p>Perfil</p>
                                    </a>
                                </li>
                                <li class="nav-item"
                                    v-if="user.permission == 'cadastrador' || user.permission == 'chefe' || user.permission == 'admin'">
                                    <a href="/createUser" class="nav-link "
                                        :class="{ 'active': $page.url === '/createUser' }">
                                        <i class="fa fa-id-badge nav-icon"></i>
                                        <p>Cadastrar Usuário</p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="/solicitados" class="nav-link"
                                        :class="{ 'active': $page.url === '/solicitados' }">
                                        <i class="fa fa-list nav-icon"></i>
                                        <p>Cartões solicitados </p><span class="badge badge-danger right"
                                            id="allSolicitados">{{ solicitado }}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
        <div class="content-wrapper">
            <div class="content">
                <div class="container-fluid">
                    <div v-if="$page.props.error.message">
                        <div class="alert alert-danger alert-dismissible fade show" role="alert">
                            <ul>
                                <li v-for="(error, index) in $page.props.error" :key="index">{{ error }}</li>
                            </ul>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible fade show" v-if="$page.props.flash.success"
                        role="alert">
                        {{ $page.props.flash.success }}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="alert alert-info alert-dismissible fade show" v-if="$page.props.flash.errors" role="alert">
                        {{ $page.props.flash.errors }}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
        <aside class="control-sidebar control-sidebar-dark">
            <div class="p-3">
                <h5>Menu</h5>
                <p>Opções: </p>
                <a class="dropdown-item" href="/logout" onclick="event.preventDefault();
                        document.getElementById('logout-form').submit();">
                    Sair
                </a>

                <form id="logout-form" action="/logout" method="POST" class="d-none">
                    <input type="hidden" name="_token" :value="$page.props.csrfToken">
                </form>
            </div>
        </aside>
        <footer class="main-footer">
            <div class="float-right d-none d-sm-inline">
                Anything you want
            </div>
            <strong>Copyright &copy; {{ new Date().getFullYear() }}
                Criado com <i class="fa fa-heart text-red"></i> por Josenildo Tiago
            </strong> All rights reserved.
        </footer>
    </div>
</template>
<script>
import axios from 'axios';
import config from '../../config.js';
axios.create({
    baseURL: config.baseUrl,
});
export default {
    props: ['csrfToken'],
    data() {
        return {
            solicitado: ''
        }
    },
    async mounted() {
        this.getAllSolicitados();
    },
    methods: {
        async getAllSolicitados() {
            axios
                .get("/api/countAllSolicitadoStatus")
                .then((response) => {
                    this.solicitado = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    computed: {
        user() {
            return this.$page.props.auth.user
        },
    },
}

</script>